import React from 'react'
import parse from 'html-react-parser';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useReport } from '../../hooks/useReport';
import { BarsLoader } from '../loaders/BarsLoader';
import { LoadingError } from './ErrorOnLoad';
import { dateToString } from '../../utils/dateToString';
import { FilesDisplay } from './FilesDisplay';
import { formatText } from '../../utils/formatText';

import "./ReportContainer.scss"


interface ReportContainerProps {
  type: string;
  date: string;
  title: string;
}

export const ReportContainer = ({type, date, title}:ReportContainerProps) => {
  const {report, loading, error, refetch} = useReport(date, type, title);

  if(loading){
    return (
      <Container className='loader-container'>
        <BarsLoader/>
      </Container>
    )
  }

  if(error || !report){
    return (
      <Container className='error-container'>
        <Row className='justify-content-center'>
          <Col xs ="10" md="8">
            <LoadingError retry={refetch}/>
          </Col>
        </Row>
      </Container>
    )
  }

  const formatedDate = dateToString(report.createdAt);
  const formatedText = formatText(report.description);

  return (
      <Row className='justify-content-center report-container'>
        <Col xs="12" md="12" id="report_col_container">
          <Card>
            <Card.Header>
              <Row>
                <Col className='type'>
                  <h5>{report.type}</h5>
                </Col>
              </Row>
              <Row>
                <Col className='title'>
                  <h3>{report.title}</h3>
                </Col>
              </Row>
              <Row>
                <Col className='date'>
                  {formatedDate}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Card.Text className="report_text">
                {parse(formatedText)}
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <FilesDisplay files={report.files}/>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
  )
}
