import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';

interface LoadingErrorProps {
  retry: () => void;
}

export const LoadingError = ({retry}:LoadingErrorProps) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>No se pudo encontrar el Reporte</Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          Verifique que la direccion sea la correcta o que el reporte exista.
        </Card.Text>
        <Card.Text>
          Si el problema persiste, contacte un administrador.
        </Card.Text>
          <Row className='justify-content-center'>
            <Col xs="6" className='text-center'>
              <Button onClick={retry}> Reintentar</Button>
            </Col>
          </Row>
      </Card.Body>
    </Card>
  )
}