import {File} from "../../../types/ReportResponse"
import {Col, Row } from 'react-bootstrap';
import { getPreviewUrl, getFileUrl } from '../../../api/api';
import "./PDFCard.scss"
import { DownloadFooter } from '../DownloadFooter';
import { FilesHeader } from '../FilesHeader';

interface RenderFileProps {
  file: File;
}

export const PDFCard = ({file}: RenderFileProps) => {

  const previewUrl = getPreviewUrl(file.code);
  const fileUrl = getFileUrl(file.code);
  return (
      <Row className='PDF_card_container justify-content-center'>
        <Col xs="12" md="10" className='text-center'>
            <div className='file_card'>
              <FilesHeader title={file.title}/>
              <a href={fileUrl} target="_blank" rel="noopener">
                <div className='file_card_body'>
                  <div className='img_container'>
                    <img src={previewUrl} alt="pdf_preview"/>
                    <div className ="img_icon_container">
                      <img className="img_icon" src="/assets/icons/zoom.png" alt='zoom_icon'/>
                    </div>
                  </div>
                </div>
              </a>
              <div>
                <DownloadFooter url={fileUrl}/>
              </div>
            </div>
        </Col>
      </Row>
  )
}
