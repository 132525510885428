import { Card } from 'react-bootstrap';

interface FilesHeaderProps {
  title : string;
}

export const FilesHeader = ({title}:FilesHeaderProps) => {
  
  if (!title || title === '') {
    return null;
  }

  return (
    <div>
      <Card.Title style={{color: "#07205d"}}>
        {title}
      </Card.Title>
    </div>
  )
  
}
