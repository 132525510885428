import React from 'react'

import './RefetchSpinner.scss'

interface Props {
  refetching: boolean;
}

export const RefetchSpinner = ( {refetching} : Props) => {
  if (!refetching) return null;
  return (
    <div className="refetch-spinner">
      <img src='/images/loader2.gif' alt="loading gif"/>
    </div>
  )
}
