import {
  useWindowWidth,
} from '@react-hook/window-size'

export const UseChartWidth = ( ) => {
  const width = useWindowWidth()
  if (width > 1200 ) {
    return 850;
  }
  if (width > 992 ) {
    return 700;
  }
  if (width > 768 ) {
    return 600;
  }
  if (width > 576 ) {
    return 500;
  }
  return 350;
}
