import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { Year } from '../../../types/Projections';
import { getName, getUnit } from './getName';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { ModalGraph } from './ModalGraph';
import { colors } from '../../expirations/charts/utils/colors';


interface Props {
  show : boolean;
  onHide : () => void;
  title : string;
  variables : (keyof Year)[];
  selectedYears : string[];
}

export const ModalOfGraphs = (
  {show, onHide, title, variables, selectedYears} : Props
) => {

  const maxIndex = variables.length - 1;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(0);
  }, [variables])

  const incrementIndex = () => {
    if (currentIndex === maxIndex) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }

  const decrementIndex = () => {
    if (currentIndex === 0) {
      setCurrentIndex(maxIndex);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  }

  const VariableNavigator = () => {
    if (variables.length === 1) {
      return (
        <Row style={{marginBottom: "-2rem"}}>
          <Col xs="12" className="text-center" style={{color: colors.lightBlue}}>
            <h4>{ getName (variables[currentIndex]) }</h4>
          </Col>
          <Col xs="12" className="text-center"style={{color: colors.darkBlue}}>
            <h6>{getUnit(variables[currentIndex])}</h6>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row style={{marginBottom: "-2rem"}}>
          <Col xs="12" className="text-center" style={{color: colors.lightBlue, display: 'flex', justifyContent: 'space-between'}}>
            <Button onClick={decrementIndex}><FaChevronLeft /></Button>
              <h4>{ getName (variables[currentIndex]) }</h4>
            <Button onClick={incrementIndex}><FaChevronRight /></Button>
          </Col>
          <Col xs="12" className="text-center"style={{color: colors.darkBlue}}>
            <h6>{getUnit(variables[currentIndex])}</h6>
          </Col>
        </Row>
      )
    }
  }

  return (
        <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onHide}
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" >
              {title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{backgroundColor : colors.backgound}}>
          <Row>
            <VariableNavigator />
          </Row>
          <Row>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <ModalGraph variable={variables[currentIndex]} selectedYears={selectedYears} />
            </div>
          </Row>
        </Modal.Body>
        </Modal>
  )
}
