import { Col, Row } from 'react-bootstrap';
import {File} from "../../../types/ReportResponse"
import { getFileUrl } from '../../../api/api';
import "./AudioCard.scss"
import { DownloadFooter } from '../DownloadFooter';
import { FilesHeader } from '../FilesHeader';

interface RenderFileProps {
  file: File;
}

export const AudioCard = ({file}: RenderFileProps) => {
  
  const fileUrl = getFileUrl(file.code);
  
  return (
    <Row className='audio_card_container justify-content-center'>
      <Col xs="12" md="10" className='mx-auto'>
        <div className="audio_card">
          <FilesHeader title={file.title}/>
          <div className='audio_card_body'>
            <audio controls>
              <source src={fileUrl} type={file.format}/>
                Tu navegador no soporta audios, por favor instala la aplicacion.
            </audio>
          </div>
          <div>
            <DownloadFooter url={fileUrl}/>
          </div>
        </div>
      </Col>
    </Row>
  )
}
