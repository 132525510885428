import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine, VictoryTheme } from 'victory';
import { InflationTable } from '../../../types/Projections';
import { colors } from '../../expirations/charts/utils/colors';
import { Col, Container, Row } from 'react-bootstrap';
import { UseChartWidth } from '../../expirations/charts/utils/UseChartWidth';


interface Props {
    inflation : InflationTable[];
}

export const InflationGraph = ({inflation} : Props) => {
    
    const width = UseChartWidth();

    const formatLabel = (value : any ) => {
        return `${value} %`;
    }
    
    const monthlyInflation = inflation.map((item) => {
        return {
            x: item.period,
            y: item.monthlyInflation
        }
    })

    const yearlyInflation = inflation.map((item) => {
        return {
            x: item.period,
            y: item.yearlyInflation
        }
    })

    const getBarWidth = () => {
        if (yearlyInflation.length > 0) {
          return ((width - 85) / yearlyInflation.length) * 0.6;
          }
        return 30;
    }

const maxYearlyInflation = Math.max(...yearlyInflation.map((value) => value.y??0));
const maxMonthlyInflation = Math.max(...monthlyInflation.map((value) => value.y??0));

const monthlyInflactionTicks = [];
for (let i = 0; i < maxMonthlyInflation+1; i++) {
    monthlyInflactionTicks.push(i/maxMonthlyInflation);
}

const yearlyInflactionTicks = [];
for (let i = 0; i < maxYearlyInflation+10; i+=10) {
    yearlyInflactionTicks.push(i/maxYearlyInflation);
}
    
return (
    <Container>
        <Row>
            <Col>
                <VictoryChart
                    width={width}
                    padding={{
                    top: 20,
                    bottom: 80,
                    left: 40,
                    right: 40,
                    }}
                    domainPadding={[getBarWidth(), getBarWidth()]}
                    theme={{
                    ...VictoryTheme.material,
                    }}
                >
                    <VictoryAxis
                    style={{
                        tickLabels: { angle: -45 },
                        ticks: {
                        stroke: 'transparent',
                        padding: 0,
                        },
                        axis: { stroke: colors.blueishGrey},
                    }}
                    tickLabelComponent={<VictoryLabel dy={-10} dx={-15}/>}
                    />

                    <VictoryAxis
                        dependentAxis
                        style={{
                            axis: { stroke: colors.lightBlue, strokeWidth: 3 },
                            grid: {
                                stroke: colors.extraLightGrey,
                                strokeWidth: 3,
                                strokeDasharray: 0,
                            },
                            
                        }}
                        tickFormat={(value) => `${(value*maxMonthlyInflation).toFixed(0)} %`}
                        tickValues={monthlyInflactionTicks}
                    />

                    <VictoryAxis
                        dependentAxis
                        style={{
                            axis: { stroke: colors.darkBlue, strokeWidth: 3 },
                            tickLabels: {
                                textAnchor: 'start',
                            },
                            ticks: {padding: -20},
                        }}
                        tickFormat={(value) => `${(value*maxYearlyInflation).toFixed(0)} %`}
                        offsetX={width-40}
                        tickValues={yearlyInflactionTicks}
                    />

                    <VictoryBar 
                    labels={({ datum }) => formatLabel(datum.y)}
                    data={yearlyInflation}
                    style={{
                        data: {
                        fill: colors.darkBlue,
                        width: getBarWidth(),
                        },
                        labels: { fill: colors.darkBlue, fontSize: 0, fontWeight: 'bold' },
                    }}
                        y={(datum) => datum.y / maxYearlyInflation}
                    />

                    <VictoryLine
                    data={monthlyInflation}
                    style={{
                        data: { stroke: colors.lightBlue, strokeWidth: 2},
                    }}
                        y={(datum) => datum.y / maxMonthlyInflation}
                    />
                </VictoryChart>
            </Col>
        </Row>
        <Row className="justify-content-center" style={{marginTop:"-1rem"}}>
            <Col xs="auto">
                <div className="d-flex align-items-center">
                <div className="mr-2" style={{ width: 15, height: 15, backgroundColor: colors.darkBlue }} />
                <span className="mr-4" style={{ fontSize: 12, marginLeft: 4 }}>Anual</span>
                </div>
            </Col>
            <Col xs="auto">
                <div className="d-flex align-items-center">
                <div className="mr-2" style={{ width: 15, height: 4, backgroundColor: colors.lightBlue}} />
                <span style={{ fontSize: 12, marginLeft: 4 }}>Mensual</span>
                </div>
            </Col>
        </Row>
    </Container>
)
}
