// same format as the email and the app
export const formatText = (text: string) => {
  let result = text.replace(
    /- (.*?)\n/g,
    `<ul style="padding-left: 15px"><li>$1</li></ul>`
  );
  result = result.replace(/- (.*?)$/g, "• &nbsp;&nbsp;&nbsp;&nbsp;$1\n\n");
  result = result.replace(/(?:\r\n|\r|\n)/g, "<br>");
  result = result.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
  result = result.replace(/_(.*?)_/g, "<i>$1</i>");

  return parseBullets(result);
};

const parseBullets = (description : string) => {
  const breaklines = description.split("<br>");
  const specialBullet = "• &nbsp;&nbsp;&nbsp;&nbsp;"; // it's created when there is no \n after a bullet
  let startedBulletsGroup = false;
  let result = "";

  breaklines.map((line) => {
    if (line.includes("- ") || line.includes(specialBullet)) {
      if (!startedBulletsGroup) {
        result += `<ul style="text-align: justify">`;
        startedBulletsGroup = true;
      }
      const sentence = line.includes("- ")
        ? line.replace(/- /g, "")
        : line.replace(specialBullet, "");
      result += `<li style="margin-bottom: 10px; margin-top:10px">${sentence}</li>`;
    } else {
      if (startedBulletsGroup) {
        result += `</ul> ${line}`;
        startedBulletsGroup = false;
      } else {
        result += line + "<br>";
      }
    }
  });
  return result;
};