import { Container } from 'react-bootstrap'
import { TableOfExpirations } from './table/TableOfExpirations'
import ExpirationsByFrequency from "./ExpirationsByFrequency"
import { useAppSelector } from '../../store/hooks';



export const ExpirationsContainerBody = () => {
    const currentTab = useAppSelector(state => state.expirations.selectedTab);
    return (
        <Container>
              <div style={{ display: currentTab === "day" ? "block" : "none" }}>
                <ExpirationsByFrequency frequency={"daily"} />
            </div>
            <div style={{ display: currentTab === "month" ? "block" : "none" }}>
                <ExpirationsByFrequency frequency={"monthly"} />
            </div>
            <div style={{ display: currentTab === "table" ? "block" : "none" }}>
                <TableOfExpirations />
            </div>
        </Container>
    )
}
