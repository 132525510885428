import React, { useEffect, useState } from 'react'
import { getExpirationTable } from '../api/api';
import { TableData } from '../types/TableResponse';
import { useAppSelector } from '../store/hooks';
import { expirationsFilters } from '../features/expirationsSlice';

const pageSizeExpirationKey = 'pageSizeExpiration';
const initialPageSize = localStorage.getItem('pageSizeExpiration') ? parseInt(localStorage.getItem('pageSizeExpiration') as string) : 20;

export const useExpirationTable = () => {

  const filters = useAppSelector(state => state.expirations.filters);
  const [expirationTable, setExpirationTable] = useState<TableData>();
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [refetching, setRefetching] = useState<boolean>(false);



  
  const fetchExpirationTable = async (filters : expirationsFilters) => {
    try{
      const response = await getExpirationTable(currentPage, pageSize, filters);
      setExpirationTable(response);
      setTotalPages(Math.ceil(response.count / pageSize));
    } catch (e) {
      console.log(e);
    }
    setRefetching(false);
    setLoading(false);    
  }


  React.useEffect(() => {
    if (!filters) return;
    setRefetching(true);
    fetchExpirationTable(filters);
    try {
      localStorage.setItem('pageSizeExpiration', pageSize.toString());
    } catch (e) {
      console.log("Could not use local storage");
    }
  }, [currentPage, pageSize, filters]);

  return {
    expirationTable,
    loading,
    currentPage,
    totalPages,
    fetchExpirationTable,
    pageSize,
    setPageSize,
    setCurrentPage,
    refetching
  }
}
