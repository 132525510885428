import React, { useState } from 'react'
import { Card, Row, Col, Button, Nav } from 'react-bootstrap'
import { FaFilter } from 'react-icons/fa'
import { useToggle } from '../../hooks/useToggle';
import { FiltersModal } from './modals/FiltersModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeTab } from '../../features/expirationsSlice';

interface props{
    onTabChange : void    
}

type selectedTab = "day" | "month" | "table";



export const ExpirationsContainerHeader = () => {

  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(state => state.expirations.selectedTab);
  const assets = useAppSelector(state => state.expirations.assets);


  const {value : showModal, toggle : toggleModal} = useToggle();

  const onTabChange = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const tab = event.currentTarget.getAttribute("data-rr-ui-event-key");
    if (tab && tab !== currentTab && (tab === "day" || tab ==="month" || tab ==="table"  ) ) {
      dispatch(changeTab(tab));
    }
  }

  return (
    <Card.Header>

      <FiltersModal show={showModal} onHide={toggleModal} assets={assets}/>
     
      <Row className="d-flex align-items-center">
        <Col xs={12} md={9} className="title">
          <h2>Vencimientos de deuda</h2>
        </Col>
      </Row>
      <Row className="d-flex align-items-center">
        <Col xs={12} md="9">
          <h5>Soberana, sub-soberana y corporativa</h5>
        </Col>
        <Col xs={12} md={3} className="d-flex justify-content-end align-items-center">
          <Button onClick={toggleModal} disabled={!assets}>
            <FaFilter size={20}/>
          </Button>
        </Col>
      </Row>
        <Nav variant="tabs" activeKey={currentTab}>
          <Nav.Item>
            <Nav.Link eventKey="day" onClick={onTabChange}>Por Día</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="month" onClick={onTabChange}>Por Mes</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="table" onClick={onTabChange}>Tabla Completa</Nav.Link>
          </Nav.Item>
        </Nav>
    </Card.Header>
  )
}
