import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/store';
import { fetchProjections } from '../../features/projectionsSlice';
import { useAppSelector } from '../../store/hooks';
import { MediumLoader } from '../loaders/MediumLoader';
import "./ProjectionsContainer.scss"    
import { MonthlyProjections } from './MonthlyProjections';
import { AnualProjections } from './AnualProjections';

type SelectedTab = "anual" | "mensual";

export const ProjectionsContainer = () => {

    const [selectedTab, setSelectedTab] = useState<SelectedTab>("anual");
    const { loading, data} = useAppSelector(state => state.projections); 
    
    const dispatch = useAppDispatch();
    const onTabChange = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        const tab = event.currentTarget.getAttribute("data-rr-ui-event-key");
        if (tab && tab !== selectedTab && (tab === "anual" || tab ==="mensual" ) ) {
            setSelectedTab(tab);
        }
    };


    useEffect(() => {
        dispatch(fetchProjections());
    }, []);


    if (loading || !data) {

        return(
            <Row className='justify-content-center' id="projections-menu-container">
                <Col xs="12" id="projections_col_container">
                    <Card>
                        <Card.Header>
                            <Row className="d-flex align-items-center">
                                <Col xs={12} className="title">
                                    <h2>Proyecciones Macroeconómicas</h2>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <MediumLoader/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }

    return (
        <Row className='justify-content-center' id="projections-menu-container">
            <Col xs="12" id="projections_col_container">
                <Card id="projections_card">
                    <Card.Header>
                        <Row className="d-flex align-items-center">
                            <Col xs={12} className="title">
                                <h2>Proyecciones Macroeconómicas</h2>
                            </Col>
                        </Row>
                        <Nav variant="tabs" activeKey={selectedTab}>
                            <Nav.Item>
                                <Nav.Link eventKey="anual" onClick={onTabChange}>Anual</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="mensual" onClick={onTabChange}>Mensual</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>
                    <Card.Body>
                        {selectedTab === "anual" && <AnualProjections data={data}/>}
                        {selectedTab === "mensual" && <MonthlyProjections data={data}/>}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
  )
}
