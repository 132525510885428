
import './MediumLoader.scss'

export const MediumLoader = () => {
  return (
    <div className="medium-loader">
      <img src='/images/loader2.gif' alt="loading gif"/>
    </div>
  )
}
