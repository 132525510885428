import  { useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useAppDispatch } from '../../store/hooks';
import { fetchExpirations } from '../../features/expirationsSlice';
import { ExpirationsContainerHeader } from './ExpirationsContainerHeader';
import { ExpirationsContainerBody } from './ExpirationsContainerBody';
import "./ExpirationsContainer.scss"


export const ExpirationsContainer = () => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchExpirations());
  }, []);

  return (
      <Row className='expirations-menu-container'>
      
          <Col xs="12" id="expirations_col_container">
            <Card>
              <ExpirationsContainerHeader/>
              <Card.Body>
                <ExpirationsContainerBody/>
              </Card.Body>
            </Card>
          </Col>
          
      </Row>
  )
}
