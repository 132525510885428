import { ActiveExpiration } from "../../types/Expirations";


// Code copied and adapted from the native app.
export const getLargestAssets = (assets : ActiveExpiration[]) => {
  let first : [string, number] = ["", 0];
  let second : [string, number] = ["", 0];
  let third : [string, number] = ["", 0];
  let fourth : [string, number] = ["", 0];
  assets.map((day) => {
    Object.entries(day).forEach((active) => {
      if (active[1] > first[1]) {
        fourth = third;
        third = second;
        second = first;
        first = active;
      } else if (active[1] > second[1]) {
        fourth = third;
        third = second;
        second = active;
      } else if (active[1] > third[1]) {
        fourth = third;
        third = active;
      } else if (active[1] > fourth[1]) {
        fourth = active;
      }
    });
  });
  return [first[0], second[0], third[0], fourth[0]];
};