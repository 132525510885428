import React from 'react'
import { TableData } from '../../../types/TableResponse';


export const renderTitleText = (title: string) => {
  return (
    <th  scope="col" className="text-center">
      <div>
        {title}
      </div>
    </th>
  )
}

export const ExpirationsTableHeader = () => {
  return (
    <thead>
      <tr>
        <th scope="col" className="text-center">Fecha</th>
        <th scope="col" className="text-center">Riesgo</th>
        <th scope="col" className="text-center">Moneda de pago</th>
        <th scope="col" className="text-center">Tipo de Activo</th>
        <th scope="col" className="text-center">Asset Class</th>
        <th scope="col" className="text-center">Activo</th>
        <th scope="col" className="text-center">Monto en ARS</th>
        <th scope="col" className="text-center">Monto en USD</th>
      </tr>
    </thead>
  )
}
