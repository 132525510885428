import { Col, Row } from 'react-bootstrap'
import "./BottomBanner.scss"

export const BottomBanner = () => {
  
  const invite_url = window.location.origin + "/invite";

  return (
    <Row id="bottom_banner_container">
        <Col xs="12" d-flex justify-content-center>
          <a href={invite_url} target="_blank" rel="noopener">
            <img src="/assets/banners/BannerIZQ_Arriba_APP_Mobile.png" alt="mobile invite" id="bottom_banner" className="center-block"/>
          </a>
        </Col>
        <Col xs="12" d-flex justify-content-center>
          <a href="https://t.me/FacimexValores" target="_blank" rel="noopener">
            <img src="/assets/banners/Banner_DER_Abajo_APP_Mobile.png" alt="telegram invite" id="bottom_banner" className="center-block"/>
          </a>
        </Col>
    </Row>
  )
}
