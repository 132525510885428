import { useParams } from 'react-router-dom'
import { ReportContainer } from '../../components/reports/ReportContainer';
import { NotFound } from '../error/404';

export const Report = () => {
  const {type, date, title} = useParams();

  if(!type || !date || !title) return <NotFound/>;
  return <ReportContainer type={type} date={date} title={title}/>
}
