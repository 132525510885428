import { Outlet } from 'react-router-dom'
import { getFacimexHome } from '../../api/api';
import "./Layout.scss"
import { LeftBanner } from './banners/LeftBanner';
import { Col, Row } from 'react-bootstrap';
import { RightBanner } from './banners/RightBanner';
import { BottomBanner } from './banners/BottomBanner';

export const Layout = () => {
  const facimexURl = getFacimexHome();

  return (
    <div id="layout_body">
      <div id="layout_header">
        <a href={facimexURl}>
          <img src="/images/facimex_logo_light.svg" alt="logo facimex"/>
        </a>
      </div>
      <div id="layout_main">
        <Row style={{margin : 0}}>
          <Col lg="2" className='d-flex justify-content-center'>
            <LeftBanner/>
          </Col>
          <Col lg="8" md="12">
            <Outlet/>
          </Col>
          <Col lg="2" className='d-flex justify-content-center'>
            <RightBanner/>
          </Col>
        </Row>
        <Row style={{margin : 0}} id="bottomBannerContainer">
          <Col md="12" className='d-flex justify-content-center'>
            <BottomBanner/>
          </Col>
        </Row>
      </div>
      <div id="layout_footer">
        <p> Facimex Valores S.A. Agente de Liquidación y Compensación y Agente de Negociación Integral registrado bajo el Nº 99 de la CNV </p>
      </div>
    </div>
  )
}
