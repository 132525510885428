import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Layout } from "./layout/Layout";
import { Report } from "../pages/report/Report";
import { NotFound } from "../pages/error/404";
import { Expirations } from "../pages/expirations/Expirations";
import { Root } from "../pages/Root";
import { Projections } from "../pages/projections/Projections";
import { InviteScreen } from "../pages/invite/InviteScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <NotFound />,
      },
      {
        path: "/informe/:type/:date/:title",
        element: <Report />,
      },
      {
        path: "/vencimientos",
        element: <Expirations />,
      },
      {
        path: "/proyecciones",
        element: <Projections />,
      },
      {
        path: "/invite",
        element: <InviteScreen />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export const AppRouter = () => {
  return <RouterProvider router={router} />;
};
