import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const NoData = () => {
  return (
        <Container >
            <Row>
                <Col>
                    <h5 style={{ textAlign: "center", marginBottom: 20 }}>
                        No se encontraron datos
                    </h5>
                </Col> 
            </Row>
            <Row>
                <Col>
                    <h6 style={{ textAlign: "center"}}>
                        Por favor, intente con otros filtros
                    </h6>
                </Col>
            </Row>
        </Container>
  )
}
