import React from 'react'
import { Tables } from '../../../types/Projections'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { InterestRateGraph } from './InterestRateGraph'


interface Props {
    data: Tables
}

export const InterestRate = ({data} : Props) => {

    const interestRate = data.interestRateTable;

    const renderLabel = (x : number | undefined) => {
        if (!x) {
            return "-";
        }
        return x.toFixed(2);
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Table bordered hover responsive>
                        <thead>
                            <tr>
                                <th scope="col" className="text-center"></th>
                                <th scope="col" className="text-center">Leliq</th>
                                <th scope="col" className="text-center">BADLAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                interestRate.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{item.period}</td>
                                            <td className="text-center">{renderLabel(item.leliq)}</td>
                                            <td className="text-center">{renderLabel(item.badlar)}</td>
                                        </tr>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InterestRateGraph interestRate={interestRate}/>
                </Col>
            </Row>
        </Container>
    )
}
