import React from 'react'
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryLine, VictoryScatter, VictoryLabel } from 'victory';
import { colors } from '../../expirations/charts/utils/colors';
import { UseChartWidth } from '../../expirations/charts/utils/UseChartWidth';

interface Props {
  data: { x: string; y: number | null }[];
  isPercentage?: boolean;
  selected: string[];
}

export const GeneralLineGraph = ({data, isPercentage, selected} : Props) => {
  
  const width = UseChartWidth();

  const formatLabel = (value: any) => {
    if (isPercentage) {
      return `${value.toFixed(1)} %`;
    } else {
      return `${value.toFixed(1)}`;
    }
  };

  const formatTick = (value: any) => {
    if (isPercentage) {
      return `${value} %`;
    } else {
      return `${value}`;
    }
  };
  
  return (
    <div>
       <VictoryChart
        width={width * 0.9}
        padding={{
          top: 20,
          bottom: 80,
          left: 65,
          right: 20,
        }}
        domainPadding={[40, 40]}
        theme={{
          ...VictoryTheme.material,
        }}
      >
        <VictoryAxis
          style={{
            tickLabels: { angle: -45 },
            ticks: {
              stroke: 'transparent',
              padding: 0,
            },
            grid: { stroke: 'transparent' },
            axis: { stroke: colors.blueishGrey, strokeWidth: 3 },
          }}
          tickLabelComponent={<VictoryLabel textAnchor="end" />}
        />

        <VictoryAxis
          dependentAxis
          style={{
            ticks: { stroke: 'transparent' },
            grid: {
              stroke: colors.extraLightGrey,
              strokeWidth: 3,
              strokeDasharray: 0,
            },
            axis: { stroke: colors.blueishGrey, strokeWidth: 3 },
          }}
          tickFormat={formatTick}
        />

        <VictoryLine
          data={data}
          style={{ data: { stroke: colors.transparentBlue4 } }}
        />
        <VictoryScatter
          data={data}
          size={5}
          style={{
            data: {
              fill: (d: any) => {
                if (selected.includes(d.data[d.index].x)) {
                  return colors.lightBlue;
                } else {
                  return colors.darkBlue;
                }
              },
            },
            labels: { color: colors.blue },
          }}
          labels={({ datum }) => formatLabel(datum.y)}
          labelComponent={<VictoryLabel dy={-15} backgroundPadding={2} backgroundStyle={{fill: colors.backgound}}/>}
        />
      </VictoryChart>
    </div>
  )
}
