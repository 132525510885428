import React from 'react'
import { Tables } from '../../../types/Projections'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { ExchangeRateGraph } from './ExchangeRateGraph'


interface Props {
    data: Tables
}

export const ExchangeRate = ({data} : Props) => {

    const exchangeRate = data.exchangeRateTable;

    const renderLabel = (x : number | undefined) => {
        if (!x) {
            return "-";
        }
        return x.toFixed(1);
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Table bordered hover responsive>
                        <thead>
                            <tr>
                                <th scope="col" className="text-center"></th>
                                <th scope="col" className="text-center">Proyección Facimex</th>
                                <th scope="col" className="text-center">Futuros Rofex</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                exchangeRate.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{item.period}</td>
                                            <td className="text-center">{renderLabel(item.facimex)}</td>
                                            <td className="text-center">{renderLabel(item.rofex)}</td>
                                        </tr>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ExchangeRateGraph exchangeRate={exchangeRate}/>
                </Col>
            </Row>
        </Container>
    )
}
