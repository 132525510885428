import React, { useEffect } from 'react'
import { getFacimexHome } from '../../api/api'

export const NotFound = () => {
  const facimexHome = getFacimexHome();

  // Redirect to facimex home
  
  useEffect(() => {
    window.location.replace(facimexHome);
  }, [facimexHome]);

  return (
    <div></div>
  )
}
