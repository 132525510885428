import { createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { getProjections } from '../api/api';
import { Tables } from '../types/Projections';

export interface ProjectionsState {
    loading: boolean;
    error: boolean;
    data : Tables | undefined;
}

const initialState: ProjectionsState = {
    loading: true,
    error: false,
    data : undefined
};

const fetchProjections = createAsyncThunk(
    'expirations/fetchAssets',
    async () => {
        const response = await getProjections();
        return response
    }
)


export const projectionsSlice = createSlice({
    name: 'projections',
    initialState : initialState,
    reducers: {
        clearError: (state) => {
            state.error = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjections.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchProjections.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.data = action.payload;
            })
            .addCase(fetchProjections.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
    }
});


export const { clearError } = projectionsSlice.actions;
export { fetchProjections };
export default projectionsSlice.reducer;