import React, { useState } from 'react'
import { Tables } from '../../types/Projections';
import { Col, Container, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import { Inflation } from './MonthlyProjections/Inflation';
import { ExchangeRate } from './MonthlyProjections/ExchangeRate';
import { InterestRate } from './MonthlyProjections/InterestRate';

interface Props {
  data: Tables;
}

type selectValues = "Inflación" | "Tasa de cambio" | "Tasa de interés";

export const MonthlyProjections = ({data} : Props) => {

  const [selectedValue, setSelectedValue] = useState<selectValues>("Inflación");

  const HandleChange = (value : selectValues) => {
    setSelectedValue(value);
  }


  const renderDetail = () => {
    switch (selectedValue) {
      case "Inflación":
        return (
          <Inflation data={data}/>
        )
      case "Tasa de cambio":
        return (
          <ExchangeRate data={data}/>
        )
      default:
        return (
          <InterestRate data={data}/>
        )
    }
  }


  return (
    <Container>
      <Row style={{ marginBottom: '1rem' }}>
        <Col xs={{ span: 12 }}>
          <div className="text-center">
            <DropdownButton title={selectedValue} id="dropdown-menu" size="lg">
              <Dropdown.Item onClick={() => HandleChange('Inflación')}>Inflación</Dropdown.Item>
              <Dropdown.Item onClick={() => HandleChange('Tasa de cambio')}>Tasa de cambio</Dropdown.Item>
              <Dropdown.Item onClick={() => HandleChange('Tasa de interés')}>Tasa de interés</Dropdown.Item>
            </DropdownButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          {renderDetail()}
        </Col>
      </Row>
    </Container>
  )
}
