
import { Card, Col, Row } from 'react-bootstrap';
import {File} from "../../../types/ReportResponse"
import { getFileUrl } from '../../../api/api';
import "./VideoCard.scss"
import { DownloadFooter } from '../DownloadFooter';
import { FilesHeader } from '../FilesHeader';

interface RenderFileProps {
  file: File;
}

export const VideoCard = ({file}: RenderFileProps) => {
  
  const fileUrl = getFileUrl(file.code);
  
  return (
    <Row className='video_card_container justify-content-center'>
      <Col xs="12" md="10" className='mx-auto'>
        <div className='video_card'>
          <FilesHeader title={file.title}/>
          <video controls>
            <source src={fileUrl} type={file.format}/>
              Tu navegador no soporta video, por favor instala la aplicacion.
          </video>
          <div>
            <DownloadFooter url={fileUrl}/>
          </div>
        </div>
      </Col>
    </Row>
  )
}
