import {File} from "../../types/ReportResponse"
import { AudioCard } from "./files/AudioCard";
import { ImageCard } from "./files/ImageCard";
import { PDFCard } from "./files/PDFCard";
import { VideoCard } from "./files/VideoCard";

interface RenderFileProps {
  file: File;
}

export const RenderFile = ({file}:RenderFileProps) => {
  switch (file.format) {
    case "application/pdf":
      return (
        <PDFCard file={file}/>
      )
    case "audio/wav":
      return (
          <AudioCard file={file}/>
      )
    case "video/mp4":
      return (
        <VideoCard file={file}/>
      )
    case "image/png":
    case "image/jpeg":
    case "image/jpg":
    case "image/gif":
      return (
        <ImageCard file={file}/>
      )
    default:
      return (
        <div>
          <h4>Formato no soportado</h4>
        </div>
      )
  }
}
