import React, { useState } from 'react'

export const useToggle = (defaultValue?: boolean) => {
    
    const [value, setvalue] = useState<boolean>(!!defaultValue);

    const toggle = () => {
        setvalue(!value);
    };

    return {value, toggle};

}