import React from 'react'
import { Tables } from '../../../types/Projections'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { InflationGraph } from './InflationGraph'

interface Props {
    data: Tables
}

export const Inflation = ({data} : Props) => {

    const inflation = data.inflationTable;


    const monthlyLabel = (x : number | undefined) => {
        if (!x) {
            return "-";
        }
        return x.toFixed(2) + "%";
    }

    const yearlyLabel = (x : number | undefined) => {
        if (!x) {
            return "-";
        }
        return x.toFixed(1) + "%";
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Table bordered hover responsive>
                        <thead>
                            <tr>
                                <th scope="col" className="text-center"></th>
                                <th scope="col" className="text-center">Mensual</th>
                                <th scope="col" className="text-center">Anual</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                inflation.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{item.period}</td>
                                            <td className="text-center">{monthlyLabel(item.monthlyInflation)}</td>
                                            <td className="text-center">{yearlyLabel(item.yearlyInflation)}</td>
                                        </tr>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InflationGraph inflation={inflation}/>
                </Col>
            </Row>
        </Container>
    )
}
