// formato : 2023-03-22T16:53:43.111Z
export const dateToString = (date: string) => {
  const fecha = new Date(date);
  const meses = [ "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic" ];
  const fechaFormateada = `${fecha.getDate()} ${meses[fecha.getMonth()]} ${fecha.getFullYear()} - ${pad(fecha.getHours())}:${pad(fecha.getMinutes())}`;
  function pad(numero: number) {
    return numero < 10 ? `0${numero}` : numero;
  }
  return fechaFormateada;
}

// returns it in YYYYMMDD format
export const dateObjectToString = (date: Date) => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}${month}${day}`;
}