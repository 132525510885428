import {configureStore} from '@reduxjs/toolkit';
import expirationReducer from '../features/expirationsSlice';
import projectionsReducer from "../features/projectionsSlice"
import { useDispatch } from 'react-redux'

export const store = configureStore({
    reducer: {
        expirations: expirationReducer,
        projections : projectionsReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch


