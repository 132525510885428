import React from 'react'
import { Col, Dropdown, DropdownButton, Pagination, Row } from 'react-bootstrap'
import { UseWidth } from '../../../hooks/useWidth';

const pageSizeOptions = [ 5, 10, 20, 50];

interface Props {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  totalPages: number;
}

export const ExpirationTableFooter = ({pageSize, setPageSize, currentPage, totalPages, setCurrentPage} : Props) => {

  const width = UseWidth();

  const handleSelect = (eventKey: any) => {
    if (eventKey && pageSizeOptions.includes(Number(eventKey))) {
      if (Number(eventKey) === pageSize) return;
      setCurrentPage(1);
      setPageSize(Number(eventKey));
    }
  }

  const firstDisabled = currentPage === 1;
  const previousDisabled = currentPage === 1;

  const nextDisabled = currentPage === totalPages;
  const lastDisabled = currentPage === totalPages;

  const handleFirst = () => {
    if (!firstDisabled) {
      setCurrentPage(1);
    }
  }
  const handlePrevious = () => {
    if (!previousDisabled) {
      setCurrentPage(currentPage - 1);
    }
  }

  const handleNext = () => {
    if (!nextDisabled) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleLast = () => {
    if (!lastDisabled) {
      setCurrentPage(totalPages);
    }
  }

  const getPageItems = () => {
    if (width < 768) {
      return (
        <Pagination.Item key={currentPage} active={true}> {currentPage}</Pagination.Item>
      )
    };

    const pageItems = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
            {i}
          </Pagination.Item>
        );
      }
    } else {
      pageItems.push(
        <Pagination.Item
          key={1}
          active={1 === currentPage}
          onClick={() => setCurrentPage(1)}
        >
          1
        </Pagination.Item>
      );

      if (currentPage > 3) {
        pageItems.push(<Pagination.Ellipsis key="ellipsis1" />);
      }

      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        pageItems.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </Pagination.Item>
        );
      }

      if (currentPage < totalPages - 2) {
        pageItems.push(<Pagination.Ellipsis key="ellipsis2" />);
      }

      pageItems.push(
        <Pagination.Item
          key={totalPages}
          active={totalPages === currentPage}
          onClick={() => setCurrentPage(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  return (
    <Row id="expiration_table_footer">
      <Col md="8" xs="12">
        <Pagination >
          <Pagination.First disabled={firstDisabled} onClick={handleFirst}/>
          <Pagination.Prev disabled={previousDisabled} onClick={handlePrevious}/>
          {getPageItems()}
          <Pagination.Next disabled={nextDisabled} onClick={handleNext}/>
          <Pagination.Last disabled={lastDisabled} onClick={handleLast}/>
        </Pagination>
      </Col>
      <Col className="page_dropdown_container" md="4" xs="12">
        <DropdownButton variant="primary" title={`Mostrar : ${pageSize}`} onSelect={handleSelect}>
          {pageSizeOptions.map((pageSizeOption) => (
            <Dropdown.Item key={pageSizeOption} eventKey={pageSizeOption}>
              {pageSizeOption}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </Col>
    </Row>
  )
}