import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'

interface DowloadFooterProps {
  url: string;
}

export const DownloadFooter = ({url}:DowloadFooterProps) => {

  return (
    <Row className='justify-content-end'>
      <Col xs="6" className='d-flex justify-content-end'>
        <a href={url + "?download=y"} download="informe.pdf">
          <Button> Descargar</Button>
        </a>
      </Col>
    </Row>
  )
}