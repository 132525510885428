import { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { useAppDispatch,useAppSelector } from "../../../store/hooks";
import { AssetsAndActivesList } from "../../../types/ExpirationAssets";
import { applyFilters, fetchExpirations } from "../../../features/expirationsSlice";
import DatePicker, {registerLocale, ReactDatePickerProps} from "react-datepicker";
import es from 'date-fns/locale/es';
import { stringToDate } from "../../../utils/stringToDate";
import { dateObjectToString } from "../../../utils/dateToString";
import "react-datepicker/dist/react-datepicker.css";
import "./FiltersModal.scss";

registerLocale('es', es);

type FiltersModal = {
  show: boolean;
  onHide: () => void;
  assets : AssetsAndActivesList | undefined;
};

export const currencyOptions = () => [
  { label: "Local (ARS)", value: "Moneda local" },
  { label: "Extranjera (USD)", value: "Moneda extranjera" },
];

export const activeTypeOptions = () => [
  { label: "Letras", value: "Letras" },
  { label: "Bonos", value: "Bonos" },
]

export const riskOptions = () => [
  { label: "Soberano", value: "Soberano" },
  { label: "Sub-soberano", value: "Sub-soberano" },
  { label: "Corporativo", value: "Corporativo" },
];


export const FiltersModal = ({ show, onHide }: FiltersModal) => {

  const assets = useAppSelector(state => state.expirations.assets);
  const filters = useAppSelector(state => state.expirations.filters);

  const dispatch = useAppDispatch();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [selectedActives, setSelectedActives] = useState<[{label:string, value:string}]>();
  const [selectedAssetClasses, setSelectedAssetClasses] = useState<[{label:string, value:string}]>();
  const [selectedCurrency, setSelectedCurrency] = useState(currencyOptions());
  const [selectedActiveType, setSelectedActiveType] = useState(activeTypeOptions());
  const [selectedRisk, setSelectedRisk] = useState(riskOptions());

  useEffect(() => {
    if (!filters) return;

    if (filters.initDate && filters.endDate) {
      setDateRange([stringToDate(filters.initDate), stringToDate(filters.endDate)]);
    }
    if (filters.actives) {
      const actives = filters.actives.split(",").map((active) => {
        return { label: active, value: active };
      });
      setSelectedActives(actives as any);
    }
    if (filters.assetClasses) {
      const assetClasses = filters.assetClasses.split(",").map((asset) => {
        return { label: asset, value: asset };
      }
      );
      setSelectedAssetClasses(assetClasses as any);
    }
    if (filters.currencys) {
      const currencys = filters.currencys.split(",").map((currency) => {
        return {
          label: currency === "Moneda local" ? "Local (ARS)" : "Extranjera (USD)",
          value: currency,
        };
      });
      setSelectedCurrency(currencys as any);
    }
    if (filters.activeTypes) {
      const activeTypes = filters.activeTypes.split(",").map((activeType) => {
        return { label: activeType, value: activeType };
      });
      setSelectedActiveType(activeTypes as any);
    }
    if (filters.risks) {
      const risks = filters.risks.split(",").map((risk) => {
        return { label: risk, value: risk };
      });
      setSelectedRisk(risks as any);
    }

  }, [filters]);
  

  const handleActiveSelect = (selectedElements: any) => {
    setSelectedActives(selectedElements);
  };

  const handleActiveTypeSelect = (selectedElements: any) => {
    setSelectedActiveType(selectedElements);
  };

  const handleRiskSelect = (selectedElements: any) => {
    setSelectedRisk(selectedElements);
  };

  const handleAssetClassSelect = (selectedElements: any) => {
    setSelectedAssetClasses(selectedElements);
  };

  const handleInputDateChange = ( event: React.ChangeEvent<HTMLInputElement>) =>{
    const dates = event as unknown as [Date | null, Date | null];
    setDateRange(dates);
  }

  const handleCurrencySelect = (selectedElements: any) => {
    setSelectedCurrency(selectedElements);
  };


  const handleApply = () => {
    onHide();
    const initDate = dateRange[0] ? dateObjectToString(dateRange[0]) : null;
    const endDate = dateRange[1] ? dateObjectToString(dateRange[1]) : null;
    const actives = (selectedActives?.length ? selectedActives.map((active) => active.value).join(",") : "");
    const assetClasses = (selectedAssetClasses?.length ? selectedAssetClasses.map((asset) => asset.value).join(",") : "");
    const currencys = (selectedCurrency?.length ? selectedCurrency.map((currency) => currency.value).join(",") : "");
    const activeTypes = (selectedActiveType?.length ? selectedActiveType.map((activeType) => activeType.value).join(",") : "");
    const risks = (selectedRisk?.length ? selectedRisk.map((risk) => risk.value).join(",") : "");
    dispatch(applyFilters({ initDate, endDate, actives, assetClasses, activeTypes, risks, currencys}));
  };

  const handleReset = () => {
    dispatch(fetchExpirations());
    onHide();
  };

  if (!assets) {
    return <></>
  }

  const activesOptions = assets.actives.map((active) => {
    return { label: active, value: active };
  });

  const assetsOptions = assets.assetClasses.map((asset) => {
    return { label: asset, value: asset };
  });
  
  const customValueRenderer = (selected : any, _options : any) => {
    if (selected.length === 0) return "Ningun item seleccionado";
    if (selected.length === _options.length) return "Todos seleccionados";
    if (selected.length <= 2) return selected.map(({ label } : any) => label).join(", ");
    return `${selected.length} items seleccionados`;
  };


  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Filtros</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="modalFilterForm">
          <Form.Group as={Row}>
            <Form.Label column xs={4}>
              Fechas:
            </Form.Label>
            <Col xs={8}>
              <Form.Group>
                <Form.Control 
                  as={DatePicker}
                  selectsRange={true}
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  disabledKeyboardNavigation
                  onChange={handleInputDateChange}
                  locale="es"
                  isClearable={true}
                  dateFormat={"dd/MM/yyyy"}
                  placeholderText="Seleccione un rango"
                />
              </Form.Group>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column xs={4}>
              Moneda de pago:
            </Form.Label>
            <Col xs={8}>
              <MultiSelect
                disableSearch={true}
                options={currencyOptions()}
                onChange={handleCurrencySelect}
                labelledBy="Select"
                value={selectedCurrency??[]}
                valueRenderer={customValueRenderer}
                overrideStrings={{selectAll: "Todos"}}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column xs={4}>
              Tipo de Activo :
            </Form.Label>
            <Col xs={8}>
              <MultiSelect
                disableSearch={true}
                options={activeTypeOptions()}
                onChange={handleActiveTypeSelect}
                labelledBy="Select"
                value={selectedActiveType??[]}
                valueRenderer={customValueRenderer}
                overrideStrings={{selectAll: "Todos"}}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column xs={4}>
              Tipo de riesgo :
            </Form.Label>
            <Col xs={8}>
              <MultiSelect
                disableSearch={true}
                options={riskOptions()}
                onChange={handleRiskSelect}
                labelledBy="Select"
                value={selectedRisk??[]}
                valueRenderer={customValueRenderer}
                overrideStrings={{selectAll: "Todos"}}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column xs={4}>
              Activos:
            </Form.Label>
            <Col xs={8}>
              <MultiSelect
                disableSearch={true}
                options={activesOptions}
                onChange={handleActiveSelect}
                labelledBy="Select"
                value={selectedActives??[]}
                valueRenderer={customValueRenderer}
                overrideStrings={{selectAll: "Todos"}}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column xs={4}>
              Asset class:
            </Form.Label>
            <Col xs={8}>
              <MultiSelect
                disableSearch={true}
                options={assetsOptions}
                onChange={handleAssetClassSelect}
                labelledBy="Select"
                value={selectedAssetClasses??[]}
                valueRenderer={customValueRenderer}
                overrideStrings={{selectAll: "Todos"}}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={handleReset}>
          Limpiar filtros
        </Button>
        <Button variant="primary" onClick={handleApply}>
          Aplicar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
