import React, { Suspense, useEffect, useState} from 'react';
import { expirationFrequency } from '../../types/Expirations';
import { useAppSelector } from '../../store/hooks';
import { MediumLoader } from '../loaders/MediumLoader';
import { Unexpected } from '../errors/Unexpected';
import { NoData } from '../errors/NoData';
import { Col, Form, Row } from 'react-bootstrap';

const ExpirationByRiskType = React.lazy( () => import("./charts/ExpirationByRiskType"));
const ExpirationsByActiveType = React.lazy( () => import("./charts/ExpirationByActiveType"));
const ExpirationsByAssetClass = React.lazy( () => import("./charts/ExpirationsByAssetClass"));
const ExpirationsByActive = React.lazy( () => import("./charts/ExpirationsByActive"));
const TotalExpirationsChart = React.lazy( () => import("./charts/TotalExpirationsChart"));

interface props {
  frequency : expirationFrequency
}

export const ExpirationsByFrequency = ( {frequency} : props) => {

  const {loading, error, expirationsByDay, expirationsByMonth, assets, largestAssets} = useAppSelector(state => state.expirations);

  const [localCurrency, setLocalCurrency] = useState(false);
  const [data, setData] = useState(expirationsByDay);
  const [showInPesos, setShowInPesos] = useState(false);

  useEffect(() => {
    if (frequency === "daily" && expirationsByDay) {
      setData(expirationsByDay);
      setLocalCurrency(expirationsByDay.allInLocalCurrency);
    }
    if (frequency === "monthly" && expirationsByMonth) {
      setData(expirationsByMonth);
      setLocalCurrency(expirationsByMonth.allInLocalCurrency);
    }
  }, [frequency, expirationsByDay, expirationsByMonth, showInPesos])
  

  if (loading) {
    return (
      <MediumLoader/>
    )
  }

  if (error || !expirationsByDay || !expirationsByMonth || !assets || !largestAssets) {
    return (
      <Unexpected/>
    )
  }

  if ( !data || data.expirationsInUSD.totalExpirations.length === 0) {
    return (
      <NoData/>
    )
  }

  const toggleCurrency = () => {
    setShowInPesos(!showInPesos);
  }

  const getTotalExpirations = () => {
    if (showInPesos && localCurrency && data.expirationsInLocalCurrency && data.expirationsInLocalCurrency.totalExpirations.length > 0) {
      return data.expirationsInLocalCurrency.totalExpirations;
    }
    return data.expirationsInUSD.totalExpirations;
  }

  const getActiveTypeExpirations = () => {
    if (showInPesos && localCurrency && data.expirationsInLocalCurrency && data.expirationsInLocalCurrency.activeTypeExpirations.length > 0) {
      return data.expirationsInLocalCurrency.activeTypeExpirations;
    }
    return data.expirationsInUSD.activeTypeExpirations;
  }

  const getRiskExpirations = () => {
    if (showInPesos && localCurrency && data.expirationsInLocalCurrency && data.expirationsInLocalCurrency.riskExpirations.length > 0) {
      return data.expirationsInLocalCurrency.riskExpirations;
    }
    return data.expirationsInUSD.riskExpirations;
  }

  const getAssetClassExpirations = () => {
    if (showInPesos && localCurrency && data.expirationsInLocalCurrency && data.expirationsInLocalCurrency.assetClassExpirations.length > 0) {
      return data.expirationsInLocalCurrency.assetClassExpirations;
    }
    return data.expirationsInUSD.assetClassExpirations;
  }
  const getActiveExpirations = () => {
    if (showInPesos && localCurrency && data.expirationsInLocalCurrency && data.expirationsInLocalCurrency.activeExpirations.length > 0) {
      console.log(data.expirationsInLocalCurrency.activeExpirations);
      return data.expirationsInLocalCurrency.activeExpirations;
    }
    return data.expirationsInUSD.activeExpirations;
  }



  return (
    <Suspense fallback={<MediumLoader/>}>
      {(localCurrency) && (<Row>
        <Col className="d-flex justify-content-end">
          <Form>
            <Form.Check
              type="switch"
              label="Mostrar en Pesos Argentinos"
              onChange={toggleCurrency}
              checked={showInPesos}
            >  
            </Form.Check>
          </Form>
        </Col>
      </Row>)}
      <TotalExpirationsChart data={getTotalExpirations()} frequency={frequency} isPesos={showInPesos}/>
      <ExpirationsByActiveType data={getActiveTypeExpirations()} frequency={frequency} isPesos={showInPesos}/>
      <ExpirationByRiskType data={getRiskExpirations()} frequency={frequency} isPesos={showInPesos}/>
      <ExpirationsByAssetClass data={getAssetClassExpirations()} assets={assets} frequency={frequency} isPesos={showInPesos}/>
      <ExpirationsByActive 
        data={getActiveExpirations()}
        assets={assets}
        frequency={frequency}
        largestAssets={largestAssets}
        activesInUse={data.activesInUse}
        isPesos={showInPesos}
      />
    </Suspense>
  )
}

export default React.memo(ExpirationsByFrequency);