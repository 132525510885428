import React from 'react'
import {File} from "../../types/ReportResponse"
import { RenderFile } from './RenderFile';
import { Container } from 'react-bootstrap';

interface FilesDisplayProps {
  files: File[];
}

export const FilesDisplay = ({files}:FilesDisplayProps) => {

  if(!files.length){
    return (
      <div></div>
    )
  }
  const filesList = files.map((file, index) => {
    return (
      <div key={file.code} className='file-container'>
        <RenderFile file={file}/>
      </div>
    )
  })

  return (
    <Container>
      {filesList}
    </Container>
  )

}
