import React from 'react'
import { Container } from 'react-bootstrap';
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryGroup, VictoryBar, VictoryLabel } from 'victory';
import { colors } from '../../expirations/charts/utils/colors';
import { UseChartWidth } from '../../expirations/charts/utils/UseChartWidth';

interface Props {
    data: { x: string; y: number | null }[];
    isPercentage?: boolean;
    selected: string[];
}

export const GeneralBarGraph = ({data, isPercentage, selected} : Props) => {
    
    const width = UseChartWidth();

    const formatLabel = (value: any) => {
        if (isPercentage) {
          return `${value.toFixed(1)} %`;
        } else {
          return `${value.toFixed(1)}`;
        }
    };

    const formatTick = (value: any) => {
        if (isPercentage) {
          return `${value} %`;
        } else {
          return `${value}`;
        }
    };

    const getBarWidth = () => {
        if (data.length > 7) {
          return 20;
        } else if (data.length < 5) {
          return 35;
        }else {
          return 40;
        }
    }
    const allnegative = data.every((d) => (d.y??0) < 0);

    const xAxisOffset = ()=>{
      if(!allnegative) return 80;
      return window.innerHeight/2 - 80;
    };

    const xAxisLabelOffset = allnegative ? -15 : 0;
    const yaxisLabelOffset = allnegative ? 30 : 0;

    return (
      <div>
          <VictoryChart
          width={width}
          {...(allnegative && { height: window.innerHeight/2 })}
          padding={{
              top: 20,
              bottom: 80,
              left: 65,
              right: 20,
          }}
          domainPadding={[getBarWidth() , getBarWidth()]}
          theme={{
            ...VictoryTheme.material,
          }}
        >
          <VictoryAxis
            style={{
              tickLabels: { fill: 'none' },
              axis: { stroke: colors.blueishGrey, strokeWidth: 3 },
              grid: { stroke: 'transparent' },
              ticks: { stroke: 'transparent', padding: 0 },
            }}
          />
          <VictoryAxis
            style={{
              tickLabels: { angle: -45 },
              ticks: {
                stroke: 'transparent',
                padding: 0,
              },
              grid: { stroke: 'transparent' },
              axis: { stroke: colors.blueishGrey, strokeWidth: 3 },
            }}
            tickLabelComponent={<VictoryLabel textAnchor="end" dy={yaxisLabelOffset} dx={xAxisLabelOffset}/>}
            offsetY={xAxisOffset()}
          />
          <VictoryAxis
            dependentAxis
            style={{
              ticks: { stroke: 'transparent' },
              grid: {
                stroke: colors.extraLightGrey,
                strokeWidth: 3,
                strokeDasharray: 0,
              },
              axis: { stroke: colors.blueishGrey, strokeWidth: 3 },
            }}
            tickFormat={formatTick}
          />
          
          <VictoryGroup data={data}>
            <VictoryBar
              labels={({ datum }) => formatLabel(datum.y)}
              style={{
                data: {
                  fill: colors.blue,
                  width: getBarWidth(),
                },
                labels: {
                  fill: colors.darkBlue,
                },
              }}
            />
            <VictoryBar
              labels={({ datum }) => formatLabel(datum.y)}
              style={{
                data: {
                  fill: (d: any) => {
                    if (selected.includes(d.data[d.index].x)) {
                      return colors.lightBlue;
                    } else {
                      return 'none';
                    }
                  },
                  width: getBarWidth(),
                },
                labels: { fill: 'none' },
              }}
            />
          </VictoryGroup>
        </VictoryChart>
      </div>
    )
}
