import { Year } from "../../../types/Projections";

export const getName = (key: keyof Year) => {
    switch (key) {
        case "PBI":
          return "PBI Real";
        case "headlineInflation":
          return "Inflación general";
        case "coreInflation":
          return "Inflación núcleo";
        case "fpExchangeRate":
          return "Tipo de cambio";
        case "promExchangeRate":
          return "Tipo de cambio";
        case "monetaryPolicyRate":
          return "Tasa de política monetaria";
        case "badlar":
          return "Tasa BADLAR";
        case "currentAccountUSDbn":
          return "Cuenta corriente";
        case "currentAccountPBI":
          return "Cuenta corriente";
        case "primaryResult":
          return "Resultado primario";
        default:
         return key;
      }
}

export const getUnit = (key: keyof Year) => {
  switch (key) {
    case "PBI":
      return "Expresado en %a/a";
    case "headlineInflation":
      return "Expresado en %a/a";
    case "coreInflation":
      return "Expresado en %a/a";
    case "fpExchangeRate":
      return "Expresado en $/USD f.p.";
    case "promExchangeRate":
      return "Expresado en $/USD prom";
    case "monetaryPolicyRate":
      return "Expresado en %f.p.";
    case "badlar":
      return "Expresado en %f.p.";
    case "currentAccountUSDbn":
      return "Expresado en USDbn";
    case "currentAccountPBI":
      return "Expresado en %PBI";
    case "primaryResult":
      return "Expresado en %PBI";
    default:
      return key;
    }
  }