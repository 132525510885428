import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine, VictoryScatter, VictoryTheme } from 'victory';
import { ExchangeRateTable } from '../../../types/Projections';
import { colors } from '../../expirations/charts/utils/colors';
import { Col, Container, Row } from 'react-bootstrap';
import { UseChartWidth } from '../../expirations/charts/utils/UseChartWidth';


interface Props {
    exchangeRate : ExchangeRateTable[];
}

export const ExchangeRateGraph = ({exchangeRate} : Props) => {
    
    const width = UseChartWidth();

    const formatLabel = (value : any ) => {
        return `${value}`;
    }

    const facimex = exchangeRate.map((item) => {
        return {
            x: item.period,
            y: item.facimex
        }
    })

    const rofex = exchangeRate.map((item) => {
        return {
            x: item.period,
            y: item.rofex
        }
    })

return (
    <Container>
        <Row>
            <Col>
                <VictoryChart
                        width={width}
                        padding={{
                            top: 20,
                            bottom: 80,
                            left: 65,
                            right: 20,
                        }}
                        domainPadding={[20, 20]}
                        theme={{
                            ...VictoryTheme.material,
                        }}
                    >

                        <VictoryAxis
                            style={{
                                tickLabels: { angle: -45 },
                                ticks: {
                                stroke: 'transparent',
                                padding: 0,
                                },
                                axis: { stroke: colors.blueishGrey},
                                grid: { stroke: 'transparent' },
                            }}
                            tickLabelComponent={<VictoryLabel dy={-10} dx={-15}/>}
                        />

                        <VictoryAxis
                            dependentAxis
                            style={{
                                ticks: { stroke: 'transparent' },
                                grid: {
                                stroke: colors.extraLightGrey,
                                strokeWidth: 3,
                                strokeDasharray: 0,
                                },
                                axis: { stroke: colors.blueishGrey, strokeWidth: 3 },
                            }}
                            tickFormat={formatLabel}
                        />

                         <VictoryScatter
                            data={rofex}
                            style={{data: {fill: colors.darkBlue}}}
                            size={width/100}
                        />
                        <VictoryScatter
                            data={facimex}
                            style={{data: {fill: colors.lightBlue}}}
                            size={width/100}
                        />

                    </VictoryChart>
            </Col>
        </Row>
            <Row className="justify-content-center align-items-center">
                <Col xs="auto">
                    <div className="d-flex flex-column align-items-center">
                    <div className="mr-2" style={{ width: 15, height: 15, borderRadius: 8, backgroundColor: colors.lightBlue, marginBottom: 5 }} />
                    <span style={{ fontSize: 12 }}>Proyección Facimex</span>
                    </div>
                </Col>
                <Col xs="auto">
                    <div className="d-flex flex-column align-items-center">
                    <div className="mr-2" style={{ width: 15, height: 15, borderRadius: 8, backgroundColor: colors.darkBlue, marginBottom: 5 }} />
                    <span style={{ fontSize: 12 }}>Futuros Rofex</span>
                    </div>
                </Col>
            </Row>
    </Container>
)
}
