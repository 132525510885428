import  { useEffect, useState } from 'react'
import { Data as Report } from '../types/ReportResponse';
import { getReportByAlias } from '../api/api';

export const useReport = ( date:string, type:string, title:string) => {

  const [mounted, setMounted] = useState(true);
  const [report, setReport] = useState<Report>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const refetch = async () => {
    setError(false);
    setLoading(true);
    try {
      const reponse = await getReportByAlias(date,type, title);
      if (mounted) {
        setReport(reponse.data);
        setLoading(false);
        setError(false);
      }
   }catch(error) {
      if (mounted) {
        setError(true);
        setLoading(false);
      }
    }
  }
  

  useEffect(() => {
    setMounted(true);
    refetch();
    return () => {
      setMounted(false);
    }
  }, [date,type, title]);

  return {
    report,
    loading,
    error,
    refetch
  }
}