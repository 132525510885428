import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { Expirations } from '../types/Expirations';
import { AssetsAndActivesList } from '../types/ExpirationAssets';
import { getExpirations, getExpirationsAssets, getExpirationsWithFilter } from '../api/api';
import { getLargestAssets } from './utils/getLargestAssets';


type selectedTab = "day" | "month" | "table";

export interface expirationsFilters {
    initDate: string | null;
    endDate: string | null;
    assetClasses: string;
    actives: string;
    activeTypes: string;
    risks: string;
    currencys: string;
}

export interface ExpirationPeriod {
    expirationsInUSD : Expirations;
    activesInUse : string[];
    expirationsInLocalCurrency : Expirations | null;
    allInLocalCurrency : boolean;
}


export interface ExpirationsState {
    selectedTab:selectedTab;
    loading: boolean;
    error: boolean;
    expirationsByDay : ExpirationPeriod | undefined;
    expirationsByMonth : ExpirationPeriod | undefined;
    assets: AssetsAndActivesList | undefined;
    largestAssets : string[] | undefined;
    filters: expirationsFilters | undefined;
}

const initialState: ExpirationsState = {
    selectedTab: "day",
    loading: true,
    error: false,
    expirationsByDay: undefined,
    expirationsByMonth: undefined,
    assets: undefined,
    largestAssets: undefined,
    filters : undefined,
};

const fetchAssets = createAsyncThunk(
    'expirations/fetchAssets',
    async () => {
        const response = await getExpirationsAssets();
        return response
    }
)

const fetchExpirations = createAsyncThunk
    (
    'expirations/fetchExpirations',
    async () => {
        const response = await Promise.all([getExpirationsAssets(), getExpirations("daily"), getExpirations("monthly")]);
        const assets = response[0];
        const expirationsByDay = response[1];
        const expirationsByMonth = response[2];
        const largestAssets = getLargestAssets(expirationsByDay.expirationsInUSD.activeExpirations);

        return {
            assets,
            expirationsByDay,
            expirationsByMonth,
            largestAssets
        };
    }
);




const applyFilters = createAsyncThunk
    <
        {
            expirationsByDay: ExpirationPeriod;
            expirationsByMonth: ExpirationPeriod;
            filters : expirationsFilters;
        },
        expirationsFilters
    >
    (
    'expirations/applyFilters',
    async (filters) => {
        const {initDate, endDate, assetClasses, actives, currencys, activeTypes, risks} = filters;
        const response = await Promise.all([getExpirationsWithFilter(initDate, endDate, assetClasses, actives, currencys, activeTypes, risks, "daily"), getExpirationsWithFilter(initDate, endDate, assetClasses, actives, currencys, activeTypes, risks, "monthly")]);
        const expirationsByDay = response[0];
        const expirationsByMonth = response[1];
        return {
            expirationsByDay,
            expirationsByMonth,
            filters
        };
    }
);



export const expirationsSlice = createSlice({
    name: 'expirations',
    initialState : initialState,
    reducers: {
        clearError: (state) => {
            state.error = false;
        },
        changeTab:(state, action : PayloadAction<selectedTab>) =>{
            state.selectedTab = action.payload;
        },
        updateFilters: (state, action : PayloadAction<expirationsFilters>) => {
            state.filters = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAssets.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchAssets.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.assets = action.payload;
            })
            .addCase(fetchAssets.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(fetchExpirations.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchExpirations.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.assets = action.payload.assets;
                state.expirationsByDay = action.payload.expirationsByDay;
                state.expirationsByMonth = action.payload.expirationsByMonth;
                state.largestAssets = action.payload.largestAssets;
            
                state.filters = {
                    initDate: null,
                    endDate: null,
                    assetClasses: action.payload.assets.assetClasses.toString(),
                    actives: action.payload.assets.actives.toString(),
                    activeTypes: "Bonos,Letras",
                    risks: "Soberano,Sub-soberano,Corporativo",
                    currencys: "Moneda local,Moneda extranjera",
                }
            })
            .addCase(fetchExpirations.rejected, (state) => {
                state.loading = false;
                state.error = true;
            })
            .addCase(applyFilters.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(applyFilters.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.expirationsByDay = action.payload.expirationsByDay;
                state.expirationsByMonth = action.payload.expirationsByMonth;
                state.filters = action.payload.filters;
            })
    }
});


export const { clearError, changeTab } = expirationsSlice.actions;
export { fetchExpirations, fetchAssets, applyFilters };
export default expirationsSlice.reducer;
