import { Card, Col, Row } from 'react-bootstrap';
import {File} from "../../../types/ReportResponse"
import { getFileUrl } from '../../../api/api';
import "./ImageCard.scss"
import { DownloadFooter } from '../DownloadFooter';
import { FilesHeader } from '../FilesHeader';

interface RenderFileProps {
  file: File;
}

export const ImageCard = ({file}: RenderFileProps) => {
  
  const fileUrl = getFileUrl(file.code);

  return (
    <Row className='image_card_container justify-content-center'>
      <Col xs="12" md="10" className='mx-auto'>
          <div className='img_card'>
            <FilesHeader title={file.title}/>
            <a href={fileUrl} target="_blank" rel="noopener">
              <img src={fileUrl} alt='report img' className='report_img'/>
              <div className ="img_icon_container">
                <img className="img_icon" src="/assets/icons/zoom.png" alt='zoom icon'/>
              </div>
            </a>
            <div>
              <DownloadFooter url={fileUrl}/>
            </div>
          </div>
      </Col>
    </Row>
  )
}
