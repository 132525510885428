import React from 'react'
import { Year } from '../../../types/Projections';
import { useAppSelector } from '../../../store/hooks';
import { GeneralBarGraph } from './GeneralBarGraph';
import { GeneralLineGraph } from './GeneralLineGraph';

interface Props {
    variable: keyof Year;
    selectedYears: string[];
}

export const ModalGraph = ({variable, selectedYears} : Props) => {

    const { data} = useAppSelector(state => state.projections);

    if (data === undefined || variable === "year") {
        return <div>loading</div>
    }

    const dataGraph = data.years.map(x => ({
        x:  x.year,
        y : x[variable]
    }))

    const renderGraph = () => {
        switch (variable) {
            case "PBI":
                return <GeneralBarGraph data={dataGraph} isPercentage selected={selectedYears} />
            case "headlineInflation":
                return <GeneralBarGraph data={dataGraph} isPercentage selected={selectedYears} />
            case "coreInflation":
                return <GeneralBarGraph data={dataGraph} isPercentage selected={selectedYears} />
            case "fpExchangeRate":
                return <GeneralLineGraph data={dataGraph} selected={selectedYears}/>
            case "promExchangeRate":
                return <GeneralLineGraph data={dataGraph} selected={selectedYears}/>
            case "monetaryPolicyRate":
                return <GeneralLineGraph data={dataGraph} isPercentage selected={selectedYears}/>
            case "badlar":
                return <GeneralLineGraph data={dataGraph} isPercentage selected={selectedYears}/>
            case "currentAccountUSDbn":
                return <GeneralBarGraph data={dataGraph} isPercentage selected={selectedYears}/>
            case "currentAccountPBI":
                return <GeneralBarGraph data={dataGraph} isPercentage selected={selectedYears}/>
            case "primaryResult":
                return <GeneralBarGraph data={dataGraph} isPercentage selected={selectedYears}/>
        }
    }
    return (
        <div style={{marginTop:"1rem"}}>
            {renderGraph()}
        </div>
    )
}
