import React from 'react'
import { TableData } from '../../../types/TableResponse';

interface Props {
  expirationTable: TableData;
}
const renderDate = (date: string) => {
  const dateObj = new Date(date);
  const day = dateObj.getUTCDate().toString().padStart(2, "0");
  const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getUTCFullYear().toString();
  const time = dateObj.toUTCString().split(" ")[4];
  return (
    <td className="text-center">
      <div>
        {`${day}/${month}/${year}`}
      </div>
    </td>
  )
}

const renderActive = (active: string) => {
  return (
    <td className="text-center">
      <div>
        {active}
      </div>
    </td>
  )
}

const renderAssetClass = (assetClass: string) => {
  return (
    <td className="text-center">
      <div>
        {assetClass}
      </div>
    </td>
  )
}

export const renderExpiration = (expiration: number) => {
  return (
    <td className="text-center">
      <div>
        {Math.round(expiration)}
      </div>
    </td>
  )
}

export const renderRisk = (risk: string) => {
  return (
    <td className="text-center">
      <div>
        {risk}
      </div>
    </td>
  )
}

export const renderCurrency = (currency: string) => {
  return (
    <td className="text-center">
      <div>
        {currency}
      </div>
    </td>
  )
}

export const renderActiveType = (activeType: string) => {
  return (
    <td className="text-center">
      <div>
        {activeType}
      </div>
    </td>
  )
}

export const renderAmountInUSD = (amountInUSD: number) => {
  return (
    <td className="text-center">
      <div>
        {amountInUSD.toFixed(2)}
      </div>
    </td>
  )
}

export const renderamountInARS = (amountInARS: number, currency: string ) => {
  if(currency === "Moneda local") {
    return (
      <td className="text-center">
        <div>
          {amountInARS.toFixed(2)}
        </div>
      </td>
    )
  } else {
    return (
      <td className="text-center">
        <div>
          {"-"}
        </div>
      </td>
    )
  }
}


export const ExpirationsTableBody = ( {expirationTable} : Props) => {
  return (
    <tbody>
    {expirationTable.debtExpirations.map((debtExpiration) => (
                    <tr key={debtExpiration._id}>
                    {renderDate(debtExpiration.date)}
                    {renderRisk(debtExpiration.risk)}
                    {renderCurrency(debtExpiration.currency)}
                    {renderActiveType(debtExpiration.activeType)}
                    {renderAssetClass(debtExpiration.assetClass)}
                    {renderActive(debtExpiration.active)}
                    {renderamountInARS(debtExpiration.ammountInLocal, debtExpiration.currency)}
                    {renderAmountInUSD(debtExpiration.ammountInUSD)}
                  </tr>
    ))
    }
  </tbody>
  )
}
