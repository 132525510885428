import { Card, Col, Row } from "react-bootstrap"

import "./InviteScreen.scss"
import { usePlatform } from "../../hooks/usePlatform";
import { useEffect } from "react";
import QRCode from 'react-qr-code';


export const InviteScreen = () => {

    const this_url = window.location.origin + "/invite";
    const platform = usePlatform();


    useEffect(() => {
        if (platform === "android") {
            window.location.href = "https://play.google.com/store/apps/details?id=com.facimex.research&hl=en&gl=US";
        } else if (platform === "ios") {
            window.location.href = "https://apps.apple.com/ar/app/facimex-research/id1595923678";
        }
    }, [platform]);
    
    

    return (
        <Row className='justify-content-center' id="InviteScreenContainer">
            <Col xs="12" id="projections_col_container">
                <Card>
                    <Card.Header>
                        <Row className="d-flex align-items-center">
                            <Col xs={12} className="title">
                                <h3>¡Descarga la App en tu celular!</h3>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row className="d-flex align-items-center">
                            <Col xs={12} className="title">
                                <h5>Escanea el código QR con tu teléfono y accede a información exclusiva en la aplicación de Facimex Research</h5>
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center">
                            <Col xs={12} className='d-flex justify-content-center' id="qr_container">
                                <QRCode value={this_url} size={256} />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}
