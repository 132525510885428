import { Col, Container, Row, Table } from 'react-bootstrap';
import { useExpirationTable } from '../../../hooks/useExpirationTable';
import { ExpirationsTableBody } from './ExpirationsTableBody';
import { ExpirationsTableHeader } from './ExpirationsTableHeader';
import { ExpirationTableFooter } from './ExpirationTableFooter';
import { RefetchSpinner } from './RefetchSpinner';
import { MediumLoader } from '../../loaders/MediumLoader';

import './TableOfExpirations.scss';
import { NoData } from '../../errors/NoData';
import { colors } from '../charts/utils/colors';

export const TableOfExpirations = () => {

  const { expirationTable, loading, currentPage, setCurrentPage,totalPages, pageSize, setPageSize, refetching } = useExpirationTable();

  if (loading) {
    return (
      <MediumLoader/>
    )
  }

  if (!expirationTable || totalPages === 0) {
    return (
      <NoData/>
    )
  }

  return (
    <Container className='table_of_expirations_container'>
      <Row>
        <Col>
          <h5 style={{ textAlign: "left", color: colors.lightBlue}}>
            Vencimientos de deuda de mercado
          </h5>
          <h6 style={{ textAlign: "left", color: colors.darkBlue, marginBottom: "1rem"}}>
            Cifras en millones de pesos
          </h6>
        </Col>
      </Row>
      <Row className="">
        <RefetchSpinner refetching={refetching}/>
        <Col>
          <Table bordered hover responsive>
            <ExpirationsTableHeader />
            <ExpirationsTableBody expirationTable={expirationTable}/>
          </Table>
        </Col>
      </Row>
      <ExpirationTableFooter
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </Container>
  )
}
