import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export const Unexpected = () => {
  return (
    <Container >
        <Row>
            <Col>
                <h5 style={{ textAlign: "center", marginBottom: 20 }}>
                    Ocurrio un error inesperado
                </h5>
            </Col> 
        </Row>
        <Row>
            <Col>
                <h6 style={{ textAlign: "center"}}>
                    Por favor, intente nuevamente. Si el problema persiste, contacte un administrador.
                </h6>
            </Col>
        </Row>
    </Container>
  )
}
