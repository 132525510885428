import { useState } from "react";
import { Tables, Year } from "../../types/Projections";
import {
  Col,
  Row,
  Table,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { IoBarChart } from "react-icons/io5";
import { ModalOfGraphs } from "./AnualProjections/ModalOfGraphs";

interface Props {
  data: Tables;
}

export const AnualProjections = ({ data }: Props) => {
  const [firstYearIndex, setFirstYearIndex] = useState(0);
  const [secondYearIndex, setSecondYearIndex] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [variables, setVariables] = useState<(keyof Year)[]>(["PBI"]);
  const [selected, setSelected] = useState<string>("Actividad Económica");

  const yearlyData = data.years;

  const years = yearlyData.map((item) => {
    return item.year;
  });

  const HandleChangeFirstYearChange = (value: number) => {
    setFirstYearIndex(value);
  };

  const HandleChangeSecondYearChange = (value: number) => {
    setSecondYearIndex(value);
  };

  const firstYear = yearlyData[firstYearIndex];
  const secondYear = yearlyData[secondYearIndex];

  const handleOpenCharts = (key: string) => {
    switch (key) {
      case "Actividad Económica":
        setVariables(["PBI"]);
        break;
      case "Inflación, tipo de cambio y tasas de interés":
        setVariables([
          "headlineInflation",
          "coreInflation",
          "fpExchangeRate",
          "promExchangeRate",
          "monetaryPolicyRate",
          "badlar",
        ]);
        break;
      case "Sector Externo":
        setVariables(["currentAccountUSDbn", "currentAccountPBI"]);
        break;
      case "Sector Público":
        setVariables(["primaryResult"]);
        break;
    }
    setShowModal(true);
    setSelected(key);
  };

  const onHideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Row>
        <ModalOfGraphs
          show={showModal}
          onHide={onHideModal}
          title={selected}
          variables={variables}
          selectedYears={[firstYear.year, secondYear.year]}
        />
        <Col>
          <div className="m-3 mt-0">Seleccione los años a comparar.</div>
          <Table responsive borderless id="AnualProjectionsTable">
            <thead>
              <tr>
                <th scope="col" className="text-center"></th>
                <th scope="col" className="text-center"></th>
                <th scope="col" className="text-center">
                  <DropdownButton title={firstYear.year} id="dropdown-menu">
                    {years.map((year, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => HandleChangeFirstYearChange(index)}
                      >
                        {year}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </th>
                <th scope="col" className="text-center">
                  <DropdownButton title={secondYear.year} id="dropdown-menu">
                    {years.map((year, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => HandleChangeSecondYearChange(index)}
                      >
                        {year}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4} className="table_subtitle">
                  <span style={{ float: "left" }}>Actividad Económica</span>
                  <div
                    style={{ float: "right", marginRight: "1rem" }}
                    className={"openChartsIcon"}
                    onClick={() => {
                      handleOpenCharts("Actividad Económica");
                    }}
                  >
                    <IoBarChart size={20} />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-left">PBI Real</td>
                <td className="text-center">{"%a/a"}</td>
                <td className="text-center">{firstYear.PBI.toFixed(1)}%</td>
                <td className="text-center">{secondYear.PBI.toFixed(1)}%</td>
              </tr>
              <tr>
                <td colSpan={4} className="table_subtitle">
                  <span style={{ float: "left" }}>
                    Inflación, tipo de cambio y tasas de interés
                  </span>
                  <div
                    style={{ float: "right", marginRight: "1rem" }}
                    className={"openChartsIcon"}
                    onClick={() => {
                      handleOpenCharts(
                        "Inflación, tipo de cambio y tasas de interés"
                      );
                    }}
                  >
                    <IoBarChart size={20} />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-left">Inflación general</td>
                <td className="text-center">{"%a/a"}</td>
                <td className="text-center">{firstYear.headlineInflation.toFixed(1)}%</td>
                <td className="text-center">{secondYear.headlineInflation.toFixed(1)}%</td>
              </tr>
              <tr>
                <td className="text-left">Inflación núcleo</td>
                <td className="text-center">{"%a/a"}</td>
                <td className="text-center">{firstYear.coreInflation.toFixed(1)}%</td>
                <td className="text-center">{secondYear.coreInflation.toFixed(1)}%</td>
              </tr>
              <tr>
                <td className="text-left">Tipo de cambio</td>
                <td className="text-center">{"$/USD f.p."}</td>
                <td className="text-center">{firstYear.fpExchangeRate.toFixed(1)}</td>
                <td className="text-center">{secondYear.fpExchangeRate.toFixed(1)}</td>
              </tr>
              <tr>
                <td className="text-left">Tipo de cambio</td>
                <td className="text-center">{"$/USD prom"}</td>
                <td className="text-center">{firstYear.promExchangeRate.toFixed(1)}</td>
                <td className="text-center">{secondYear.promExchangeRate.toFixed(1)}</td>
              </tr>
              <tr>
                <td className="text-left">Tasa de política monetaria</td>
                <td className="text-center">{"%f.p."}</td>
                <td className="text-center">{firstYear.monetaryPolicyRate.toFixed(1)}%</td>
                <td className="text-center">
                  {secondYear.monetaryPolicyRate}%
                </td>
              </tr>
              <tr>
                <td className="text-left">Tasa BADLAR</td>
                <td className="text-center">{"%f.p."}</td>
                <td className="text-center">{firstYear.badlar.toFixed(1)}%</td>
                <td className="text-center">{secondYear.badlar.toFixed(1)}%</td>
              </tr>
              <tr>
                <td colSpan={4} className="table_subtitle">
                  <span style={{ float: "left" }}>Sector Externo</span>
                  <div
                    style={{ float: "right", marginRight: "1rem" }}
                    className={"openChartsIcon"}
                    onClick={() => {
                      handleOpenCharts("Sector Externo");
                    }}
                  >
                    <IoBarChart size={20} />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-left">Cuenta corriente</td>
                <td className="text-center">{"USDbn"}</td>
                <td className="text-center">{firstYear.currentAccountUSDbn.toFixed(1)}</td>
                <td className="text-center">
                  {secondYear.currentAccountUSDbn.toFixed(1)}
                </td>
              </tr>
              <tr>
                <td className="text-left">Cuenta corriente</td>
                <td className="text-center">{"%PBI"}</td>
                <td className="text-center">{firstYear.currentAccountPBI.toFixed(1)}%</td>
                <td className="text-center">{secondYear.currentAccountPBI.toFixed(1)}%</td>
              </tr>
              <tr>
                <td colSpan={4} className="table_subtitle">
                  <span style={{ float: "left" }}>Sector Público</span>
                  <div
                    style={{ float: "right", marginRight: "1rem" }}
                    className={"openChartsIcon"}
                    onClick={() => {
                      handleOpenCharts("Sector Público");
                    }}
                  >
                    <IoBarChart size={20} />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-left">Resultado primario</td>
                <td className="text-center">{"%PBI"}</td>
                <td className="text-center">{firstYear.primaryResult.toFixed(1)}%</td>
                <td className="text-center">{secondYear.primaryResult.toFixed(1)}%</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
