import axios from 'axios';
import { ReportResponse } from '../types/ReportResponse';
import { ExpirationsResponse, expirationFrequency } from '../types/Expirations';
import { ExpirationAssetsResponse } from '../types/ExpirationAssets';
import { TableResponse } from '../types/TableResponse';
import { expirationsFilters } from '../features/expirationsSlice';
import { ProjectionsResponse } from '../types/Projections';

// const URL = process.env.REACT_APP_BACKEND || "http://localhost:5000";
const URL = process.env.REACT_APP_BACKEND || "https://api.facimex-qa.creativecoefficient.net";  // CORREGIR ESTO
const FACIMEX_HOME = process.env.REACT_APP_FACIMEX_HOME || "https://www.facimexvalores.com.ar/";

export const API = axios.create({
  baseURL: URL,
});

API.interceptors.request.use((config) => {
  config.headers['clientType'] = "reports-web";
  return config;
});

export const getReport = async (id:string) => {
  const response = await API.get<ReportResponse>(`/report/${id}`);
  if(response.status === 200 && response.data.status === "success") {
    return response.data;
  }
  throw new Error("No se pudo obtener el reporte");
}

export const getPreviewUrl = (id:string) => {
  return `${URL}/report/file/${id}/preview`;
}

export const getFileUrl = (id:string) => {
  return `${URL}/report/file/${id}`;
}

export const getFacimexHome = () => {
  return FACIMEX_HOME;
}

export const getReportByAlias = async (date:string, type:string, title:string) => {
  //date: YYMMDD-HHMM
  const response = await API.get<ReportResponse>(`/report/withalias/${type}/${date}/${title}`);
  if(response.status === 200 && response.data.status === "success") {
    return response.data;
  }
  throw new Error("No se pudo obtener el reporte");
}


export const getExpirations = async (frequency : expirationFrequency) => {
  const response = await API.get<ExpirationsResponse>(`/debtExpiration/live/all/${frequency}`);
  if(response.status === 200 && response.data.status === "success") {
    return response.data.data;
  }
  throw new Error("No se pudieron obtener los vencimientos");
}


export const getExpirationsAssets = async () => {
  const response = await API.get<ExpirationAssetsResponse>(`/debtExpiration/live/activesAndAssetClasses`);
  if(response.status === 200 && response.data.status === "success") {
    return response.data.data;
  }
  throw new Error("No se pudieron obtener los vencimientos");
}

export const getExpirationTable = async (page : number, limit : number, filters : expirationsFilters) => {

  const {initDate, endDate, assetClasses, actives} = filters;
  const dateQuery = initDate && endDate ? `&initDate=${initDate}&endDate=${endDate}` : "";

  const response = await API.post<TableResponse>(`/debtExpiration/live/all?sort=date:asc&page=${page}&limit=${limit}${dateQuery}`,{
    assetClasses,
    actives
  });
  if (response.status === 200 && response.data.status === "success") {
    return response.data.data;
  }
  throw new Error("No se pudo obtener la tabla de vencimientos");
}

//date format is YYYYMMDD
export const getExpirationsWithFilter = async (initDate : string | null , endDate: string | null, assetClasses : string, actives : string, currencys : string, activeTypes : string, risks : string, frequency : expirationFrequency ) => {
  const dateQuery = initDate && endDate ? `?initDate=${initDate}&endDate=${endDate}` : "";
  
  const response = await API.post<ExpirationsResponse>(`/debtExpiration/live/all/${frequency}${dateQuery}`, {
    assetClasses,
    actives,
    activeTypes,
    currencys,
    risks
  });
  if(response.status === 200 && response.data.status === "success") {
    return response.data.data;
  }
  throw new Error("No se pudieron obtener los vencimientos filtrados");
}


export const getProjections = async () => {
  const response = await API.get<ProjectionsResponse>(`/macro/all/live`);
  if(response.status === 200 && response.data.status === "success") {
    return response.data.data.tables;
  }
  throw new Error("No se pudieron obtener las proyecciones");
}